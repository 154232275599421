<template>
  <div id="assigned-subjects" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table id="list" :data="assignedSubjects" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          assignedSubjects.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="School Class" v-slot="props">{{
          props.row.schoolClass.name
        }}</b-table-column>

        <b-table-column label="Number of Students" v-slot="props">{{
          props.row.numberOfStudents
        }}</b-table-column>

        <b-table-column label="Number of Subjects" v-slot="props">{{
          props.row.numberOfSubjects
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <p class="buttons">
            <a
              class="is-small has-text-info"
              @click="initializeInfo(props.row)"
              style="font-size: 1.1em"
            >
              <fa-icon icon="info-circle" size="lg" />
            </a>
          </p>
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'assigned-subjects',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Assigned Subjects',
      items: [],
      records: [],
      assignedSubjects: [],
      subjects: [],
      schoolClasses: [],
    }
  },
  methods: {
    initializeInfo(assigned_subject) {
      this.$router.push(
        `/school/${this.schoolId}/assigned_subject_info/${assigned_subject.schoolClass.id}`
      )
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'New',
        route: `/school/${this.schoolId}/assigned_subject/new`,
      },
    ])

    this.$apollo.addSmartQuery('assignedSubjects', {
      query: gql`
        query AssignedClassesSimplifiedQuery($schoolId: Int!) {
          assignedClassesSimplified(schoolId: $schoolId) {
            id
            schoolClass {
              id
              name
            }
            numberOfStudents
            numberOfSubjects
            educator {
              id
              name
            }
          }
        }
      `,
      variables: {
        schoolId: this.schoolId,
      },
      update: (data) => data.assignedClassesSimplified,
    })

    this.$apollo.queries.assignedSubjects.refetch()
  },
}
</script>
